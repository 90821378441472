import * as React from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "./components/loading/Loading";
import * as loadable from 'react-loadable';
import ProtectedRoute from "./components/protected-route/ProtectedRoute";
import { useThunkDispatch } from "./utils/thunk-dispatch";
import { bindActionCreators } from "redux";
import { getCurrentUser } from "./features/user-feature";
import { useSelector } from "react-redux";
import { AppState } from "./interfaces/app-state";
import { User } from "./interfaces/user";
import { Request } from "./interfaces/Request";

const AsyncHomeContainer = loadable({ loader: () => import(/* webpackChunkName: "Home" */ "./containers/home/Home"), loading: Loading });
const AsyncLoginContainer = loadable({ loader: () => import(/* webpackChunkName: "Login" */ "./containers/authentication/Login"), loading: Loading });
const AsyncForgotPasswordContainer = loadable({ loader: () => import(/* webpackChunkName: "ForgotPassword" */ "./containers/authentication/ForgotPassword"), loading: Loading });
const AsyncResetPasswordContainer = loadable({ loader: () => import(/* webpackChunkName: "ResetPassword" */ "./containers/authentication/ResetPassword"), loading: Loading });
const AsyncRegisterContainer = loadable({ loader: () => import(/* webpackChunkName: "Register" */ "./containers/authentication/Register"), loading: Loading });
const AsyncLogoutContainer = loadable({ loader: () => import(/* webpackChunkName: "Logout" */ "./containers/authentication/Logout"), loading: Loading });
const AsyncSupportContainer = loadable({ loader: () => import(/* webpackChunkName: "Support" */ "./containers/support/Support"), loading: Loading });
const AsyncProfileContainer = loadable({ loader: () => import(/* webpackChunkName: "Profile" */ "./containers/profile/Profile"), loading: Loading });
const AsyncInstitutionContainer = loadable({ loader: () => import(/* webpackChunkName: "Institutions" */ "./containers/institution/Institutions"), loading: Loading });
const AsyncCreateInstitutionContainer = loadable({ loader: () => import(/* webpackChunkName: "CreateInstitution" */ "./containers/institution/Create"), loading: Loading });
const AsyncUpdateInstitutionContainer = loadable({ loader: () => import(/* webpackChunkName: "UpdateInstitution" */ "./containers/institution/Update"), loading: Loading });
const AsyncInstitutionDashboardContainer = loadable({ loader: () => import(/* webpackChunkName: "InstitutionDashboard" */ "./containers/institution/Dashboard"), loading: Loading });
const AsyncCreateEmployeeDeploymentContainer = loadable({ loader: () => import(/* webpackChunkName: "CreateEmployeeDeployment" */ "./containers/employee-deployment/Create"), loading: Loading });
const AsyncUpdateEmployeeDeploymentContainer = loadable({ loader: () => import(/* webpackChunkName: "UpdateEmployeeDeployment" */ "./containers/employee-deployment/Update"), loading: Loading });
const AsyncEmployeeContainer = loadable({ loader: () => import(/* webpackChunkName: "Employees" */ "./containers/employee/Employees"), loading: Loading });
const AsyncCreateEmployeeContainer = loadable({ loader: () => import(/* webpackChunkName: "CreateEmployee" */ "./containers/employee/Create"), loading: Loading });
const AsyncUpdateEmployeeContainer = loadable({ loader: () => import(/* webpackChunkName: "UpdateEmployee" */ "./containers/employee/Update"), loading: Loading });
const AsyncPreparationTimeContainer = loadable({ loader: () => import(/* webpackChunkName: "PreparationTime" */ "./containers/preparation-time/PreparationTime"), loading: Loading });
const AsyncEmployeeWorktimeContainer = loadable({ loader: () => import(/* webpackChunkName: "EmployeeWorktimes" */ "./containers/employee-worktime/EmployeeWorktimes"), loading: Loading });
const AsyncEmployeeWorktimeDetailContainer = loadable({ loader: () => import(/* webpackChunkName: "EmployeeWorktimesDetail" */ "./containers/employee-worktime/Detail"), loading: Loading });
const AsyncHolidaysContainer = loadable({ loader: () => import(/* webpackChunkName: "Holidays" */ "./containers/holidays/Holidays"), loading: Loading });
const AsyncAbsencesContainer = loadable({ loader: () => import(/* webpackChunkName: "Absences" */ "./containers/absences/Absences"), loading: Loading });
const AsyncUserManagement = loadable({ loader: () => import(/* webpackChunkName: "UserManagement" */ "./containers/user-management/UserManagement"), loading: Loading });
const AsyncEmployeeTimeTracking = loadable({ loader: () => import(/* webpackChunkName: "EmployeeTimeTracking" */ "./containers/employee-timeTracking/EmployeeTimeTracking"), loading: Loading });
const AsyncEmployeeTimeTrackingRequest = loadable({ loader: () => import(/* webpackChunkName: "EmployeeTimeTracking" */ "./containers/employee-timeTracking-request/EmployeeTimeTrackingRequest"), loading: Loading });
const AsyncReports = loadable({ loader: () => import(/* webpackChunkName: "Reports" */ "./containers/reports/Reports"), loading: Loading });

const AsyncNotFoundContainer = loadable({ loader: () => import(/* webpackChunkName: "NotFound" */ "./containers/error-pages/NotFound"), loading: Loading });

const Routes: React.FC = () => {
    const asyncDispatch = useThunkDispatch();
    const actions = bindActionCreators({ getCurrentUser }, asyncDispatch);
    const requests = useSelector<AppState, Request[]>(state => state.requests);
    const [userRequestStatus, setUserRequestStatus] = React.useState("REQUEST");
    const user = useSelector<AppState, User>((state) => state.user);

    React.useEffect(() => {
        actions.getCurrentUser();
    }, []);

    React.useEffect(() => {
        var userRequestString = requests.filter(x => x.type === "user/GET_CURRENT").length > 0 ? requests.filter(x => x.type === "user/GET_CURRENT")[0].status : "REQUEST";
        setUserRequestStatus(userRequestString);
    }, [requests]);

    return (
        <Switch>
            <Route exact path="/" render={() => <AsyncHomeContainer />} />
            <Route exact path="/login/:url?/:key?" render={() => <AsyncLoginContainer />} />
            <Route exact path="/forgot-password" render={() => <AsyncForgotPasswordContainer />} />
            <Route exact path="/reset-password" render={() => <AsyncResetPasswordContainer />} />
            <Route exact path="/register" render={() => <AsyncRegisterContainer />} />
            <Route exact path="/logout" render={() => <AsyncLogoutContainer />} />
            <Route exact path="/support" render={() => <AsyncSupportContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} exact path="/profile" render={() => <AsyncProfileContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} exact path="/institutions" render={() => <AsyncInstitutionContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} authorizedRoles={["Administrator", "Benutzer"]} exact path="/institutions/create" render={() => <AsyncCreateInstitutionContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} exact path="/institutions/:institutionId/dashboard" render={() => <AsyncInstitutionDashboardContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} authorizedRoles={["Administrator", "Benutzer"]} exact path="/institutions/:institutionId/deployments/create" render={() => <AsyncCreateEmployeeDeploymentContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} authorizedRoles={["Administrator", "Benutzer"]} exact path="/institutions/:institutionId/update/" render={() => <AsyncUpdateInstitutionContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} authorizedRoles={["Administrator", "Benutzer"]} exact path="/institutions/:institutionId/deployments/update/:employeeDeploymentId" render={() => <AsyncUpdateEmployeeDeploymentContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} exact path="/institutions/:institutionId/holidays" render={() => <AsyncHolidaysContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} exact path="/institutions/:institutionId/absences" render={() => <AsyncAbsencesContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} exact path="/employees" render={() => <AsyncEmployeeContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} authorizedRoles={["Administrator", "Benutzer"]} exact path="/employees/create" render={() => <AsyncCreateEmployeeContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} authorizedRoles={["Administrator", "Benutzer"]} exact path="/employees/update/:employeeId" render={() => <AsyncUpdateEmployeeContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} exact path="/employee-deployments/:employeeDeploymentId/preparation-time" render={() => <AsyncPreparationTimeContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} exact path="/employee-deployments/:employeeDeploymentId/dashboard" render={() => <AsyncEmployeeWorktimeContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} exact path="/employee-deployments/:employeeDeploymentId/month/:monthId" render={() => <AsyncEmployeeWorktimeDetailContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} authorizedRoles={["Administrator"]} exact path="/user-management" render={() => <AsyncUserManagement />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} authorizedRoles={["Administrator", "Benutzer", "Mitarbeiter"]} exact path="/employee-timetracking" render={() => <AsyncEmployeeTimeTracking />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} authorizedRoles={["Administrator", "Benutzer"]} exact path="/employee-timetracking-requests" render={() => <AsyncEmployeeTimeTrackingRequest />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} user={user} exact path="/reports" render={() => <AsyncReports />} />
            <Route component={AsyncNotFoundContainer} />
        </Switch>
    );
}

export default Routes;