import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      marginTop: theme.spacing(5),
    },
    icon: {
      fontSize: "175px",
    },
    title: {
        marginBottom: 0
    },
    subTitle: {
        marginTop: theme.spacing(1),
        color: theme.palette.grey[700]
    }
  })
);

const BrowserNotSupported: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SentimentDissatisfiedIcon className={classes.icon} />
      <h1 className={classes.title}>Dieser Browser wird momentan leider nicht unterstützt.</h1>
      <h3 className={classes.subTitle}>Bitte benutze einen der folgenden Browser: Chrome / Firefox / Edge / Safari</h3>
    </div>
  );
};

export default BrowserNotSupported;
