import { User, defaultUser } from "./user";
import { UiState, defaultUiState } from "./ui-state";
import { Request } from "./Request";
import { Exception, defaultException } from "./exception";

export interface AppState {
  requests: Request[];
  user: User;
  uiState: UiState;
  exception: Exception;
}

export const initialState: AppState = {
  requests: [],
  user: defaultUser,
  uiState: defaultUiState,
  exception: defaultException
};
