import * as React from "react";
import { drawerWidth } from "../components/header/Header";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Routes from "../routes";
import { bindActionCreators } from "redux";
import { useThunkDispatch } from "../utils/thunk-dispatch";
import { getCurrentUser } from "../features/user-feature";
import { getSelectedYear } from "../features/ui-feature";
import { isIE } from "react-device-detect";
import BrowserNotSupported from "../components/browser-not-supported/BrowserNotSupported";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "block",
      flexGrow: 1,
      padding: theme.spacing(3),
      [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      paddingBottom: "100px",
    },
    toolbar: theme.mixins.toolbar,
  })
);

const App: React.FC = () => {
  const classes = useStyles();
  const asyncDispatch = useThunkDispatch();
  const actions = bindActionCreators(
    { getCurrentUser, getSelectedYear },
    asyncDispatch
  );

  React.useEffect(() => {
    actions.getCurrentUser();
    actions.getSelectedYear();
  }, []);

  return (
    <>
      <Header />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {isIE ? (
          <BrowserNotSupported />
        ) : (
          <Routes />
        )}
      </main>
      <Footer />
    </>
  );
};

export default App;
