import axios, { AxiosResponse } from "axios";
import { User } from "../interfaces/user";
import { PaginatedList } from "../interfaces/paginated-list";

export const getAll = (page: number, rowsPerPage: number) : Promise<AxiosResponse<PaginatedList<User>>> => {
    return axios.get(`/users/getAll?page=${page}&rowsPerPage=${rowsPerPage}`);
}

export const getCurrent = () => {
    return axios.get("/users/getCurrent");
}

export const update = (user: User) => {
    return axios.post("/users/update/", user);
}

export const remove = (userId: string) => {
    return axios.delete("/users/delete?id=" + userId)
}

export const updateAssignedInstitutions = (user: User) => {
    return axios.post("/users/updateAssignedInstitutions/", user);
}
