import * as React from "react";
import { useSelector } from "react-redux";
import { User } from "../../interfaces/user";
import { AppState } from "../../interfaces/app-state";

interface Props {
  children: React.ReactNode;
  authorizedRoles: string[];
  fallbackText?: string;
}

const ProtectedContent: React.FC<Props> = (props: Props) => {
  const currentUser = useSelector<AppState, User>((s) => s.user);

  return props.authorizedRoles && props.authorizedRoles.find(x => x === currentUser.roleName) ? (
    <>{props.children}</>
  ) : props.fallbackText ? (
    <small style={{textAlign:"center"}}>{props.fallbackText}</small>
  ) : null;
};

export default ProtectedContent;
