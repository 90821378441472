import { UserInstitution } from "./user-institution";

export interface User {
    [key: string]: any
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    roleName: string;
    password: string;
    confirmPassword: string;
    staffNumber: string;
    isJumper: boolean;
    contractNumber: string;
    isActive: boolean;
    userInstitutions: UserInstitution[];
    assignedEmployeeIds: number[];
}

export const defaultUser: User = {
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    roleName: "",
    confirmPassword: "",
    staffNumber: "",
    contractNumber: "",
    isJumper: false,
    isActive: true,
    userInstitutions: [],
    assignedEmployeeIds: [],
}