import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SyncLoader from "react-spinners/SyncLoader";
import ClipLoader from "react-spinners/ClipLoader";
import clsx from "clsx";
import { drawerWidth } from "../header/Header";

interface Props {
  isLoading: boolean;
  isInline?: boolean; // is the spinner inside a button?
  pastDelay?: boolean;
  timedOut?: boolean;
  error?: any;
  retry?: () => void;
}

  const useStyles = makeStyles((theme : Theme) =>
  createStyles({
    wrapper: {
      position: "fixed",
      textAlign: "center",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.up('sm')]: {
        left: `calc(50% + ${drawerWidth / 2}px)`,
      },
      zIndex: 100
    },
    inlineWrapper: {
      position: "absolute",
      lineHeight: "0",
      left: "50%",
    },    
    button: {
      marginTop: theme.spacing(1),
    }
  })
);

function load(props: Props) {
  const classes = useStyles();

  if (props.error) {
    // When the loader has errored
    return (
      <div className={classes.wrapper}>
        Ein Fehler ist aufgetreten!<br/>
        <Button className={classes.button} size="small" variant="contained" color="primary" onClick={props.retry}>Erneut versuchen</Button>
      </div>
    );
  } else if (props.timedOut) {
    // When the loader has taken longer than the timeout
    return (
      <div className={classes.wrapper}>
        Hoppla - Die Seite braucht länger als sonst.<br/>
        <Button className={classes.button} size="small" variant="contained" color="primary" onClick={props.retry}>Erneut versuchen</Button>
      </div>
    );
  } else if (props.isLoading || props.pastDelay) {
    // When the loader has taken longer than the delay
    return (
      <div
        className={clsx(classes.wrapper, {
          [classes.inlineWrapper]: props.isInline,
        })}
      >
        {props.isInline ? (
          <ClipLoader loading size={20} color={"#ffffff"} />
        ) : (
          <SyncLoader loading />
        )}
      </div>
    );
  } else {
    // When the loader has just started
    return null;
  }
}

const Loading: React.FC<Props> = (props: Props) => {
  return load(props);
};

export default Loading;
