import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { AppState } from "../../interfaces/app-state";
import { User } from "../../interfaces/user";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Navigation from "../navigation/Navigation";
import clsx from "clsx";

export const drawerWidth = 250;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    drawer: {
      [theme.breakpoints.up("lg")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      backgroundColor: theme.palette.primary.dark,
      [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    title: {
      flexGrow: 1,
      lineHeight: 1
    },
    link: {
      color: "white",
      textDecoration: "none",
    },
  })
);

const Header: React.FC = () => {
  const classes = useStyles();
  const user = useSelector<AppState, User>((state) => state.user);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="profileMenu"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem component={RouterLink} to="/profile" onClick={handleMenuClose}>
        Mein Profil
      </MenuItem>
      <MenuItem component={RouterLink} to="/logout" onClick={handleMenuClose}>
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <div className={clsx(classes.root, "noPrint")}>
      <div className={classes.drawer}>
        <Hidden lgUp implementation="css">
          <SwipeableDrawer
            anchor={"left"}
            open={isOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Navigation toggleDrawer={toggleDrawer} />
          </SwipeableDrawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Navigation toggleDrawer={toggleDrawer} />
          </Drawer>
        </Hidden>
      </div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            <RouterLink to="/" className={classes.link}>
              Personalplanung <span className="mobileHidden">Schule Dietikon</span>
            </RouterLink>
          </Typography>
          {user && user.id ? (
            <MenuItem onClick={handleMenuOpen}>
              <IconButton
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>
              <p>
                {user.firstName} {user.lastName}
              </p>
            </MenuItem>
          ) : (
            <Button color="inherit" component={RouterLink} to="/login">
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
};

export default Header;
