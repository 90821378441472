import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

const useTextColorStyles = makeStyles((theme: Theme) =>
  createStyles({
    positiveDifference: {
      color: green[600],
    },
    negativeDifference: {
      color: red[900],
    },
    error: {
      color: "#f44336"
    }
  })
);

export default useTextColorStyles;