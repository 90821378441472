import { ActionType, createAction } from "typesafe-actions";
import { UiState, defaultUiState } from "../interfaces/ui-state";
import { Dispatch } from "redux";

export const setSelectedYearSuccess = createAction("ui/SET_SELECTED_YEAR")<string>();
export const getSelectedYearSuccess = createAction("ui/GET_SELECTED_YEAR")<string>();

export const setSelectedYear = (selectedYear: string) => {
  return (dispatch: Dispatch) => {
    dispatch(setSelectedYearSuccess(selectedYear));
    localStorage.setItem("selectedYear", selectedYear.toString());
  };
};

export const getSelectedYear = () => {
  return (dispatch: Dispatch) => {
    if (localStorage.getItem("selectedYear")) {
      dispatch(
        getSelectedYearSuccess(
          localStorage.getItem("selectedYear") || defaultUiState.selectedYear
        )
      );
    }
  };
};

export type UiActions = ActionType<
  typeof setSelectedYearSuccess | typeof getSelectedYearSuccess
>;

export const uiReducer = (
  uiState: UiState = null,
  action: UiActions
): UiState => {
  switch (action.type) {
    case "ui/SET_SELECTED_YEAR":
      return { ...uiState, selectedYear: action.payload };
    case "ui/GET_SELECTED_YEAR":
      return { ...uiState, selectedYear: action.payload };
  }

  return uiState;
};
