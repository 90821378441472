export interface Exception {
  [key: string]: any;
  message: string;
  innerException: Exception;
}

export const defaultException: Exception = {
  message: "",
  innerException: null
};
