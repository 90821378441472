import axios from "axios";
import { LoginDto } from "../interfaces/dto/login-dto";
import { PasswordResetDto } from "../interfaces/dto/password-reset-dto";
import { InvitationDto } from "../interfaces/dto/invitation-dto";
import { RegisterDto } from "../interfaces/dto/register-dto";

export const getPublicRecaptchaKey = () => {
  return axios.get("/authentication/getPublicRecaptchaKey");
};

export const login = (loginDto: LoginDto) => {
  return axios.post("/authentication/login", loginDto);
};

export const register = (registerDto: RegisterDto) => {
  return axios.post("/authentication/register", registerDto);
}

export const recover = (email: string) => {
  return axios.post(`/authentication/recoverPassword?email=${email}`);
};

export const reset = (passwordResetDto: PasswordResetDto) => {
  return axios.post(`/authentication/resetpassword`, passwordResetDto);
};

export const invite = (invitation: InvitationDto) => {
  return axios.post("/authentication/invite", invitation);
};

export const resendInvitation = (email: string) => {
  return axios.get(`/authentication/resendInvitation?email=${email}`);
};
