import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import ChartIcon from "@material-ui/icons/BarChart";
import HomeIcon from "@material-ui/icons/Home";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import ManageUsersIcon from "@material-ui/icons/VpnKey";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import InboxIcon from "@material-ui/icons/Inbox";

import { NavLink as RouterLink } from "react-router-dom";
import ProtectedContent from "../protected-content/ProtectedContent";

interface Props {
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 240,
    },
    toolbar: theme.mixins.toolbar,
    logo: {
      width: "249px",
      paddingLeft: "18px",
      paddingRight: "15px",
      marginTop: "18px",
    },
    activeLink: {
      color: theme.palette.primary.light,
      "& .MuiListItemIcon-root": {
        color: theme.palette.primary.light,
      },
    },
  })
);

const Navigation: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => props.toggleDrawer(true)}
      onKeyDown={() => props.toggleDrawer(false)}
    >
      <div className={classes.toolbar}>
        <RouterLink to="/">
          <img src="/assets/img/logo.png" className={classes.logo} />
        </RouterLink>
      </div>
      <Divider />
      <List>
        <ProtectedContent authorizedRoles={["Administrator", "Benutzer", "Gast"]}>
          <ListItem button component={RouterLink} to="/" activeClassName={classes.activeLink} exact>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItem>
        </ProtectedContent>
        <ProtectedContent authorizedRoles={["Administrator", "Benutzer", "Gast"]}>
          <ListItem button component={RouterLink} to="/institutions" activeClassName={classes.activeLink}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={"Betreuungs-einrichtungen"} />
          </ListItem>
        </ProtectedContent>
        <ProtectedContent authorizedRoles={["Administrator", "Benutzer", "Gast"]}>
          <ListItem button component={RouterLink} to="/employees" activeClassName={classes.activeLink}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={"Mitarbeitende"} />
          </ListItem>
        </ProtectedContent>
        <ProtectedContent authorizedRoles={["Administrator", "Benutzer", "Mitarbeiter"]}>
          <ListItem button component={RouterLink} to="/employee-timetracking" activeClassName={classes.activeLink}>
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
            <ListItemText primary={"Mehrstunden"} />
          </ListItem>
        </ProtectedContent>
        <ProtectedContent authorizedRoles={["Administrator", "Benutzer"]}>
          <ListItem
            button
            component={RouterLink}
            to="/employee-timetracking-requests"
            activeClassName={classes.activeLink}
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={"Mehrstunden-Anträge"} />
          </ListItem>
        </ProtectedContent>

        <ProtectedContent authorizedRoles={["Administrator", "Benutzer", "Gast"]}>
          <ListItem button component={RouterLink} to="/reports" activeClassName={classes.activeLink}>
            <ListItemIcon>
              <ChartIcon />
            </ListItemIcon>
            <ListItemText primary={"Auswertungen"} />
          </ListItem>
        </ProtectedContent>
      </List>
      <Divider />
      <List>
        <ListItem button component={RouterLink} to="/support" activeClassName={classes.activeLink}>
          <ListItemIcon>
            <ContactSupportIcon />
          </ListItemIcon>
          <ListItemText primary={"Support"} />
        </ListItem>
        <ProtectedContent authorizedRoles={["Administrator"]}>
          <ListItem button component={RouterLink} to="/user-management" activeClassName={classes.activeLink}>
            <ListItemIcon>
              <ManageUsersIcon />
            </ListItemIcon>
            <ListItemText primary={"Benutzerverwaltung"} secondary="Logins verwalten" />
          </ListItem>
        </ProtectedContent>
      </List>
    </div>
  );
};

export default Navigation;
