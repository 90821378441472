import * as React from "react";
import * as ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from "./containers/App";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { rootReducer } from "./features/root-reducer";
import { initialState } from "./interfaces/app-state";
import { configAxios } from "./utils/axios";
import CssBaseline from "@material-ui/core/CssBaseline";
import ErrorAlert from "./components/error-alert/ErrorAlert";

const w: any = window as any;
const devtools: any = w.__REDUX_DEVTOOLS_EXTENSION__
  ? w.__REDUX_DEVTOOLS_EXTENSION__()
  : (f: any) => f;

const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(thunk), devtools)
);

configAxios();

ReactDOM.render(
  <Provider store={store}>
    <CssBaseline />
    <Router history={createBrowserHistory()}>
      <ErrorAlert />
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
