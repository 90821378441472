import { Exception } from "../interfaces/exception";

export const getExceptionMessage = (
  exceptionObj: Exception,
  messages: string[]
): any => {
  // catch validation errors (they have a different format for what ever reason)
  if (
    exceptionObj.title &&
    exceptionObj.title == "One or more validation errors occurred."
  ) {
    messages.push("One or more validation errors occurred.");
    return messages;
  } else {
    messages.push(exceptionObj.Message ? exceptionObj.Message : exceptionObj);
    if (exceptionObj.InnerException) {
      return getExceptionMessage(exceptionObj.InnerException, messages);
    } else {
      return messages;
    }
  }
};
